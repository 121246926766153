;(function ( $ ) {

    /**
     *  confirmDialog
     *  show confirmation dialog before critical actions are executed
     **/

    'use strict';

    // Create the defaults once
    var pluginName = 'confirmDialog';
    var defaults = {
        defaultMsg: 'Are you sure?'
    };

    // The actual plugin constructor
    function Plugin ( element, options ) {
        this.element = element;
        this.settings = $.extend( {}, defaults, options );
        this._defaults = defaults;
        this._name = pluginName;
        this.init();
    }

    // Avoid Plugin.prototype conflicts
    $.extend(Plugin.prototype, {
        init: function () {
            this.registerEvents();
            this.message = $(this.element).data('msg-confirm');
            if (!this.message) {
                this.message = this.settings.defaultMsg;
            }
        },
        registerEvents: function() {
            var _this = this;
            $(this.element).on('click', function(e) {
                if (!window.confirm(_this.message)) {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                }
            });
        }
    });

    $.fn[ pluginName ] = function ( options ) {
        return this.each(function() {
            if ( !$.data( this, 'plugin_' + pluginName ) ) {
                $.data( this, 'plugin_' + pluginName, new Plugin( this, options ) );
            }
        });
    };
})( jQuery );
