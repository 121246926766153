window.initPlugins = function(elem) {
    $('[data-toggle="tooltip"]').tooltip();

    elem.find('.submit-on-change').on('change', function(e) {
        $(this).parents('form').submit();
    });

    elem.find('.confirm').confirmDialog();
}

jQuery(document).ready(function() {
    initPlugins($('body'));
});
